/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Regex to transform youtu.be URL into embed
  function getId(url) {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = url.match(regExp);

      if (match && match[2].length == 11) {
          return match[2];
      } else {
          return 'error';
      }
  }

  var cache = [];
  // Arguments are image paths relative to the current page.
  $.preLoadImages = function() {
    var args_len = arguments.length;
    for (var i = args_len; i--;) {
      var cacheImage = document.createElement('img');
      cacheImage.src = arguments[i];
      cache.push(cacheImage);
    }
  }  

  // jQuery.preLoadImages("path/to/image1.gif", "/path/to/image2.png");


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.flexslider').flexslider({
          animation: 'slide',
          controlNav: false
        });   

        $('#nav-primary-toggle').click(function(){          
          
          $('#nav-primary').toggleClass('show');
          
        });  
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        
       var viewportWidth = $(window).width();
       if(viewportWidth > 1024){
        $( '#player' ).vide('http://localhost:3000/mugako/video/mugako');
        $('#home_headliners li').hover(
          function() {
            //$( this ).addClass( 'hover' );            
            var image = $( this ).data( 'image' ); 
            if(image){ 
              $( '#home_headliners_bg' ).css( 'background-image' , 'url(' + image + ')' ).toggleClass('blend');
            }
          }, 
          function() {
            //$( this ).removeClass( 'hover');
            var image = $( this ).data( 'image' ); 
            if(image){ 
              $( '#home_headliners_bg' ).css( 'background-image' , 'none' ).toggleClass('blend');
            }
          });

         

        }

        

       
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'template_festival': {
      init: function() {
        // JavaScript to be fired on the about us page          
           $('#mugako-video').click(function(){                      
            var youtube = $(this).data('video'); 
            var myId = getId(youtube);              
            $('body').append('<div class="shadow" id="shadow"><iframe width="640" height="480" src="//www.youtube.com/embed/' + myId + '" frameborder="0" allowfullscreen></iframe></div>');          
          });   
         
          $(document).on('click','#shadow',function(){                    
            $('#shadow').remove();
          });

      }
    }
  
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
